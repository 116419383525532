import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Entry from './Entry';
import Register from './Register';
import ShareItem from './ShareItem';
import PartnerRelation from './PartnerRelation';
import SharedFamily from './SharedFamily';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route } from "react-router-dom";

ReactDOM.render(
  <Router>  
    <Route exact path="/" component={App}></Route>
    <Route exact path="/entry" component={Entry}></Route>
    <Route exact path="/entry/:token" component={Entry}></Route>
    <Route exact path="/register" component={Register}></Route>
    <Route exact path="/register/:token" component={Register}></Route>
    <Route exact path="/share_item" component={ShareItem}></Route>
    <Route exact path="/share_item/:token" component={ShareItem}></Route>
    <Route exact path="/relation" component={PartnerRelation}></Route>
    <Route exact path="/relation/:token" component={PartnerRelation}></Route>
    <Route exact path="/family" component={SharedFamily}></Route>
    <Route exact path="/family/:token" component={SharedFamily}></Route>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
