import React from 'react';
import './App.css';
import { RouteComponentProps } from 'react-router';

type urlProps = {} & RouteComponentProps<{token: string}>;

const Register: React.FC<urlProps> = (props) => {
  return (
    <div className="msgBox">
      <p>登録が完了しました。</p>
      <p>Payeeアプリよりログインしてご利用お願いします。</p>
    </div>
  );
};
export default Register;
